<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="$store.state.servers.all"
      :items-per-page="50"
      class="elevation-1"
    >
      <template v-slot:item.action="{ item }">
        <v-icon small class="mr-2" @click="disconnectItem(item)"
          >link_off</v-icon
        >
        <v-icon small class="mr-2" @click="getRedirect(item)">message</v-icon>
        <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
        <v-icon small @click="deleteItem(item)">delete</v-icon>
      </template>
    </v-data-table>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Server Redirect</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="serverRedirect.redirectServerUrl"
                    label="redirectServerUrl"
                    placeholder="https://slot1.slotbase.net"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-switch
                    v-model="serverRedirect.isRedirectServer"
                    :label="`IsRedirectServer`"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false"
              >Close</v-btn
            >
            <v-btn color="blue darken-1" text @click="saveRedirect">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import router from "@/router";
var md5 = require("md5");
// import moment from "moment";

export default {
  data() {
    return {
      serverRedirect: {
        redirectServerUrl: "",
        isRedirectServer: false,
      },
      current: {},
      dialog: false,
      headers: [
        {
          text: "id",
          align: "left",
          sortable: false,
          value: "id",
        },
        { text: "ip", value: "ip" },
        { text: "connections", value: "connections" },
        { text: "machineName", value: "machineName" },
        { text: "status", value: "status" },
        { text: "isDeploy", value: "isDeploy" },
        { text: "url", value: "url" },
        { text: "Version", value: "version" },
        { text: "Actions", value: "action", sortable: false },
      ],
    };
  },
  methods: {
    async saveRedirect() {
      this.serverRedirect.url = this.current.url;
      await this.$store.dispatch("servers/postRedirect", this.serverRedirect);
      this.dialog = false;
    },
    async getRedirect(item) {
      this.current = item;
      await this.$store.dispatch("servers/getRedirect", item);
      this.serverRedirect = this.$store.state.servers.redirectServer;
      this.dialog = true;
    },
    editItem(item) {
      router.push("/config/servers/detail/" + item["id"]);
    },
    async deleteItem(item) {
      await this.$store.dispatch("servers/delete", item);
    },
    disconnectItem(item) {
      this.$store.dispatch("rooms/allDisconnectPlayer", item);
    },

    Tos(s) {
      return md5(`733420e9b9906ba93942d4027bfdb24c${s}`);
    },
  },
  async mounted() {
    await this.$store.dispatch("servers/getAll");
    // this.deleteDeviceInfo();
    // const servers = db.collection("servers");
  },
};
</script>
